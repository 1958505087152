import React, {useState, useEffect, useRef} from 'react';
import useInput from '../utils/customHooks/useInput';
import {sha512, sendRequest, notify} from '../utils/functions';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import LoadPanel from './LoadPanel';
import {vars} from '../utils/variables';
import '../styles/SharpayLogin.scss';

const {AUTH_TYPE, SUCCESS_API_CODE} = vars;

const SharpayLogin = (props) => {
  const recaptchaRef = useRef(null);
  const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const {order: {Urlid}, isMobile, handleLogin, history} = props;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v';
    document.body.appendChild(script);

    script.onload = () => {
      recaptchaRef.current = window.grecaptcha;
    };

    return () => {
      document.body.removeChild(script);
      recaptchaRef.current = null;
      document.querySelector('div.grecaptcha-badge').remove();
    };
  }, []);

	const [login, loginInput] = useInput({
		type: 'text',
		id: 'login',
		name: 'login',
		placeholder: t('login'),
	});
	const [password, passwordInput] = useInput({
		type: 'password',
		id: 'password',
		name: 'password',
		placeholder: t('password'),
	});
	const [otp, otpInput] = useInput({
		type: 'password',
		id: 'otp',
		name: 'otp',
		placeholder: 'OTP',
	});

	const goBack = () => {
		history.push(`/checkout/list/${Urlid}`);
	};

	const setLoginIn = (value) => {
		handleLogin(value);
	};

	const [isOtp, setOtp] = useState(false);

	const doLogin = async () => {
		setIsLoading(true);
		const hashedPassword = await sha512(password);

		if (hashedPassword) {
      const token = await recaptchaRef.current.execute('6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v', {action: 'Login'});

			const response = await sendRequest('Login', {
				Login: login,
				Password: hashedPassword,
        RecaptchaToken: token,
			});

			if (response && response['ResponseCode'] === SUCCESS_API_CODE) {
				if (parseInt(response['Response']['AuthTypeID']) === AUTH_TYPE.SIMPLE) {
					setLoginIn(true);
				} else {
					setOtp(true);
				}
			} else {
				notify(response && response['ResponseText']);
			}
			setIsLoading(false);
		} else {
			console.log('Crypto API undefined');
		}
	};

	const validateLoginOtp = async () => {
		const optResponse = await sendRequest('Login/ConfirmByCode', {
			Params: {
				Code: otp
			}
		});

		if (optResponse && optResponse['ResponseCode'] === SUCCESS_API_CODE) {
			setLoginIn(true);
		} else {
			notify(optResponse['ResponseText']);
		}
	};

	const [isLoginDisabled, setLoginDisabled] = useState(false);

	useEffect(() => {
		setLoginDisabled(login && password);
	}, [login, password]);

	return isLoading ? (
    <LoadPanel/>
	) : (
		<div className={'sharpay-login-wrapper'}>
			<div className={'sharpay-login-form'}>
				<div className={'sharpay-login-title'}>
          {`${t('authorization')}:`}
				</div>
				<div className={'sharpay-login-row'}>
					<label htmlFor="login">
            {`${t('login')}*`}
					</label>
					<div className={'sharpay-login-input login-input'}>
						{loginInput}
					</div>
				</div>
				<div className={'sharpay-login-row'}>
					{isOtp ? (
						<>
							<label htmlFor="otp">
								OTP*
							</label>
							<div className={'sharpay-login-input password-input'}>
								{otpInput}
							</div>
						</>
					) : (
						<>
							<label htmlFor="password">
                {`${t('password')}*`}
							</label>
							<div className={'sharpay-login-input password-input'}>
								{passwordInput}
							</div>
						</>
					)}
				</div>
			</div>
			<div className={classnames('sharpay-login-buttons', {
				'mobile-buttons': isMobile
			})}>
				<button
					onClick={goBack}
				>
          {t('cancel')}
				</button>
				<button
					disabled={!isLoginDisabled}
					className={!isLoginDisabled ? 'button-disabled' : 'button-enabled'}
					onClick={isOtp ? validateLoginOtp : doLogin}
				>
          {t('next')}
        </button>
			</div>
		</div>
	);
};

export default SharpayLogin;
