import React, {useEffect, useRef, useState} from 'react';
import {copyTextToClipboard, fetchCheckoutOrder, sendRequest, notify} from '../utils/functions';
import LoadPanel from '../components/LoadPanel';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames/bind';
import QRCode from 'react-qr-code';

import {vars} from '../utils/variables';
import '../styles/SharpayAccounts.scss';
import '../styles/Crypto.scss';

const {SUCCESS_API_CODE, STATUS} = vars;

const currencyString = ({CurrencyName, CurrencyCode, BlockchainName}, {
	Amount: orderAmount,
	CurrencySymbol: orderCurrencySymbol
}) => {
	const name = CurrencyName ? `${CurrencyName} ` : '';
	const code = CurrencyCode ? CurrencyCode : '';
	const blockChain = BlockchainName ? `${BlockchainName} ` : '';
	// return `${name}(${code}) - ${blockChain} ${orderAmount}${orderCurrencySymbol}`;
	return `${name}(${code}) - ${blockChain}`;
};

const parseNewLineText = (errorText = '') => {
	return errorText.split('\n').map((text, index) => {
		return (
			<p key={index}>{text}</p>
		);
	});
};

const Crypto = (props) => {
  const {t} = useTranslation();
	const {
		order,
		isMobile,
		history,
	} = props;
	const {Urlid, MerchantReturnSuccessUrl} = order;
	const [accounts, setAccounts] = useState([]);
	const [summary, setSummary] = useState(null);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isCopying, setIsCopying] = useState(null);
	const [checkedRadio, setCheckedRadio] = useState('address');
	const address = useRef(null);
	const memo = useRef(null);
	const amount = useRef(null);

	const goBack = () => {
		history.push(`/checkout/list/${Urlid}`);
	};

	useEffect(() => {
		if (isCopying) {
			hideHint().catch((e) => {
				console.error(e);
			});
		}
	}, [isCopying]);

	const hideHint = async () => {
		const delay = new Promise((resolve => setTimeout(resolve, 2000)));
		await delay;
		setIsCopying(null);
	};

	const copyAddress = () => {
		setIsCopying('address');
		const text = address.current.textContent;
		copyTextToClipboard(text);
	};

	const copyMemo = () => {
		setIsCopying('memo');
		const text = memo.current.textContent;
		copyTextToClipboard(text);
	};

	const copyAmount = () => {
		setIsCopying('amount');
		const text = amount.current.textContent;
		copyTextToClipboard(text);
	};

	const [status, setStatusResponse] = useState({
		statusId: -1,
		count: 0,
	});

	useEffect(() => {
		if (summary) {
			const timer = setTimeout(() => {
				fetchCheckoutOrder(Urlid, setStatusResponse, STATUS.NEW).catch((e) => {
					console.error(e);
				});
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [summary]);

	useEffect(() => {
		if (status.statusId > 0) {
			history.push(`/checkout/status/${Urlid}`);
		}
	}, [status]);

	useEffect(() => {
		if (accounts.length > 0) {
			setIsLoading(false);
		}

		if (summary) {
			setIsLoading(false);
		}
	}, [accounts, summary]);

	useEffect(() => {
		const getAccounts = async () => {
			const response = await sendRequest('CheckoutOrder/GetCryptoCurrency', {
				Params: {
					Urlid: Urlid
				},
				Sorts: ['CurrencyName', 'BlockchainName'],
			});

			// order['ResponseCode'] === SUCCESS_API_CODE
			if (response && response['ResponseCode'] === SUCCESS_API_CODE) {
				const cryptoAccounts = response['Response']['CryptoCurrency'].map((item, key) => {
					return {...item, UniqID: `${item.ID}-${key}`};
				});
				setAccounts(cryptoAccounts);
			} else {
				notify(response['ResponseText']);
			}
		};

		getAccounts().catch((e) => {
			console.log(e);
		});
	}, []);

	const checkoutPayment = async () => {
		setIsLoading(true);
		const checkout = await sendRequest('CheckoutOrder/GetCryptoAddress', {
			Params: {
				Urlid: Urlid,
				CurrencyID: selectedAccount.ID,
				BlockchainID: selectedAccount.BlockchainID,
			}
		});

		if (checkout && checkout['ResponseCode'] === SUCCESS_API_CODE) {
			setSummary(checkout['Response']);
		} else {
			notify(checkout['ResponseText']);
		}
	};

	const onChangeValue = (event) => {
		const uniqID = event.target.value;
		const selectedAccount = accounts.find(account => account.UniqID === uniqID);

		setSelectedAccount(selectedAccount);
	};

	const onRadioChange = (event) => {
		const value = event.target.value;
		setCheckedRadio(value);
	};

	if (isLoading) {
		return (<LoadPanel/>);
	}

	return (
		<div className={'sharpay-payment'}>
			{!summary ? (
        <div className={'accounts-wrapper scroll-wrapper'}>
          <div className={'scroll-item'}>
            <div className={'accounts-head'}>
              <div className={'accounts-title'}>
                {`${t('currency_selection')}:`}
              </div>
            </div>
            <div onChange={onChangeValue} className={'accounts'}>
              {accounts.map(({
                               Amount, BlockchainName, ImageUrl, UniqID,
                               CurrencyCode, CurrencyName, Symbol, ViewPrecision
                             }) => {
                const amount = (typeof Amount === 'number') ? Amount.toFixed(ViewPrecision) : Amount;
                return (
                  <div key={UniqID} className={'acc-wrap'}>
                    <div className={classNames('account', {
                      'selected-account': selectedAccount && selectedAccount.UniqID === UniqID
                    })}>
                      <div className="form_radio">
                        <input id={`radio-${UniqID}`} type="radio" name="radio" value={UniqID}/>
                        <label htmlFor={`radio-${UniqID}`}>
                          {!isMobile && (
                            <div className={'crypto-img'}>
                              <img className={'account-icon'} src={ImageUrl} alt=""/>
                            </div>
                          )}
                          <div className={'crypto-account-number'}>
                            <div className={'crypto-code'}>
                              <span className={'bold-text'}>{CurrencyCode}</span>
                              <span>{CurrencyName}</span>
                            </div>
                            <div className={'block-code'}>
                              <span>{BlockchainName}</span>
                            </div>
                          </div>
                          <div className={'account-balance'}>
                            <span>{`${amount} ${Symbol}`}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classNames('accounts-buttons', {
            'mobile-buttons': isMobile
          })}>
            <button
              onClick={goBack}
            >
              {t('cancel')}
            </button>
            <button
              disabled={!selectedAccount}
              className={!selectedAccount ? 'button-disabled' : 'button-enabled'}
              onClick={checkoutPayment}
            >
              {t('next')}
            </button>
          </div>
        </div>
      ) : (
        <div className={'accounts-wrapper scroll-wrapper'}>
          <div className={'scroll-item'}>
            <div className={'accounts-head'}>
              <div className={'accounts-title'}>
                <div className={'crypto-currency-head'}>
                  {!isMobile && (
                    <div className={'crypto-img'}>
                      <img className={'account-icon'} src={selectedAccount.ImageUrl} alt=""/>
                    </div>
                  )}
                  <div className={'crypto-currency'}>
                    {currencyString(selectedAccount, order)}
                  </div>
                </div>
              </div>
            </div>
            <div className={'crypto-summary'}>
              <div className={'crypto-qr'}>
                {checkedRadio === 'address' ? (
                  <a href={`bitcoin:${summary.Address}`} target={'_blank'} rel={'noopener noreferrer'}>
                    <QRCode value={`bitcoin:${summary.Address}`} size={175}/>
                  </a>
                ) : (
                  <a href={`bitcoin:${summary.Memo}`} target={'_blank'} rel={'noopener noreferrer'}>
                    <QRCode value={`bitcoin:${summary.Memo}`} size={175}/>
                  </a>
                )}
              </div>
              <div className={'crypto-fields'}>

                <div className={'crypto-field'}>
                  <div className={'crypto-label'}>Address</div>
                  <div className={'crypto-input crypto-text'}>
                    <input
                      checked={checkedRadio === 'address'}
                      className={'crypto-radio'}
                      value={'address'}
                      id={'radio-1'}
                      type={'radio'}
                      name={'radio'}
                      onChange={onRadioChange}
                    />
                    <label className={'radio-label'} htmlFor="radio-1"></label>
                    <i onClick={copyAddress} className={'crypto-copy'}>
										<span className={classNames('copy-hint', {
                      'copy-hint-visible': isCopying === 'address',
                    })}>{t('copied')}</span>
                    </i>
                    <div ref={address} className={'input-text'}>
                      {summary.Address}
                    </div>
                  </div>
                </div>
                {summary.Memo && (
                  <div className={'crypto-field'}>
                    <div className={'crypto-label'}>Memo / Destination tag</div>
                    <div className={'crypto-input crypto-text'}>
                      <input
                        checked={checkedRadio === 'memo'}
                        className={'crypto-radio'}
                        value={'memo'}
                        id={'radio-2'}
                        type={'radio'}
                        name={'radio'}
                        onChange={onRadioChange}
                      />
                      <label className={'radio-label'} htmlFor="radio-2"></label>
                      <i onClick={copyMemo} className={'crypto-copy'}>
											<span className={classNames('copy-hint', {
                        'copy-hint-visible': isCopying === 'memo',
                      })}>{t('copied')}</span>
                      </i>
                      <div ref={memo} className={'input-text'}>
                        {summary.Memo}
                      </div>
                    </div>
                  </div>
                )}
                <div className={'crypto-field'}>
                  <div className={'crypto-label'}>{t('amount')}</div>
                  <div className={'crypto-input crypto-text'}>
                    <i onClick={copyAmount} className={'crypto-copy copy-amount'}>
											<span className={classNames('copy-hint', {
                        'copy-hint-visible': isCopying === 'amount',
                      })}>{t('copied')}</span>
                    </i>
                    <div ref={amount} className={'input-text'}>
                      {summary.Amount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'crypto-info'}>
              {summary.InfoText ? (
                <div className={'crypto-info-inner'}>
                  {parseNewLineText(summary.InfoText)}
                </div>
              ) : null}
            </div>
          </div>
          <div className={'status-button'}>
            <button
              onClick={() => {
                if (MerchantReturnSuccessUrl) {
                  window.location.href = MerchantReturnSuccessUrl
                } else {
                  notify(t('link_not_provided'));
                }
              }}>
              {t('to_the_store')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Crypto;
